<template>
  <div>
    <div class="columns">
     <b-field class="column is-half">
       <p class="control">
          <b-select v-model="searchType" placeholder="Search">
            <option value="paymentPlanId">Payment plan</option>
            <option value="bookingReference">Booking reference</option>
            <option value="email">Email address</option>
          </b-select>
        </p>
        <b-input
          v-model="searchInput"
          expanded
          placeholder="Search input" type="search" icon="magnify">
        </b-input>
        <p class="control">
            <b-button label="Search" type="is-primary"
            @click="searchPaymentData()"/>
        </p>
    </b-field>
    </div>
    <b-table  @select="selected"
        :data="cleanPaymentData"
        hoverable
        selectable
        class="table-row">
          <b-table-column
          field="createdDate"
          label="Date "
          v-slot="props">
                    {{ props.row.createdDate }}
                </b-table-column>

          <b-table-column
          field="customer.names"
          label="Customer"
          v-slot="props">
                    {{ props.row.customer.names.join(" ") }}
                </b-table-column>
          <b-table-column
          field="customer.email"
          label="Email"
          v-slot="props">
                    {{ props.row.customer.email || "--" }}
                </b-table-column>
                <b-table-column
          field="merchant.name"
          label="Merchant"
          v-slot="props">
                      {{ props.row.merchant.name }}
                </b-table-column>
                 <b-table-column
          field="bookingReference"
          label="Booking Reference"
          v-slot="props">
                    {{ props.row.bookingReference }}
                </b-table-column>
                 <b-table-column
          field="paymentPlanId"
          label="Payment Plan ID "
          v-slot="props">
                    {{ props.row.paymentPlanId }}
                </b-table-column>
    </b-table>
    <infinite-loading @infinite="infiniteHandler" ref="infiniteLoader">
      <div slot="no-more"><br>Displaying all plans</div>
      <div slot="no-results">No plans found</div>
    </infinite-loading>
  </div>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';

export default {
  name: 'PaymentPlansList',
  components: { InfiniteLoading },
  props: {
    tenantCode: String,
  },
  data() {
    return {
      cleanPaymentData: [],
      cursor: '',
      paymentPlanId: null,
      searchInput: null,
      searchType: 'paymentPlanId',
      bookingReference: null,
      email: null,
      merchants: null,
      dataService: null,
    };
  },
  async created() {
    this.dataService = this.$dataService(this.tenantCode);
  },
  methods: {
    goToPaymentPlan(paymentPlanId) {
      this.$router.push({ name: 'paymentPlanOverview', params: { paymentPlanId } });
    },
    searchPaymentData() {
      if (this.searchType === 'paymentPlanId' && this.searchInput !== null && this.searchInput !== '') {
        return this.goToPaymentPlan(this.searchInput);
      }

      if (this.searchType === 'paymentPlanId' && (this.searchInput === null || this.searchInput === '')) {
        return false;
      }

      this.cleanPaymentData = [];
      this.cursor = '';

      this.$refs.infiniteLoader.stateChanger.reset();
      this.$refs.infiniteLoader.status = 1;
      this.$refs.infiniteLoader.$emit('infinite', this.$refs.infiniteLoader.stateChanger);

      return true;
    },
    selected(item) {
      this.goToPaymentPlan(item.paymentPlanId);
    },
    async infiniteHandler($state) /* istanbul ignore next */ {
      await this.retrievePaymentData($state);

      if (this.cleanPaymentData.length) $state.loaded();
      if (!this.cursor) $state.complete();
    },
    async retrievePaymentData() {
      const query = { maxItems: 20 };

      if (this.searchType && this.searchInput !== null && this.searchInput !== '') {
        query[this.searchType] = this.searchInput;
      }

      if (this.cursor) query.cursor = this.cursor;

      const { data } = await this.dataService.cleanPaymentData.list(query);

      if (data.data.length) {
        this.cleanPaymentData = this.cleanPaymentData.concat(data.data);
      }

      this.cursor = data.cursor;
    },
  },
};
</script>
<style scoped>
dt {

    display: block;
    float: left;
    min-width: 100px;
}

dt::after {
  content: ':'
}
</style>
