<template>
  <div class="main-view">
    <payment-plans-list :tenant-code="tenant"></payment-plans-list>
  </div>
</template>

<script>
import PaymentPlansList from '@/components/paymentPlans/PaymentPlansList.vue';

export default {
  name: 'Landing',
  components: { PaymentPlansList },
  data() {
    return {
      tenant: this.$route.params.tenant,
      isLoading: false,
    };
  },
};
</script>
